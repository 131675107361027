import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../../context/auth-context";
import "./leftBar.scss";
import SidebarToggle from "../navbar/SidebarToggle";
import freedomLogo from "../../assets/img/logo/freedom/logo.svg";
import demoLogo from "../../assets/img/demo-logo.svg";
import healthSunLogo from "../../assets/img/logo/healthsun/logo.svg";
import optimumLogo from "../../assets/img/logo/Optimum/logo.svg";
import healthSunLogoIcon from "../../assets/img/healthsun-icon.svg";
import freedomLogoIcon from "../../assets/img/freedom-icon.svg";
import optimumLogoIcon from "../../assets/img/optimum-icon.svg";
import demoLogoIcon from "../../assets/img/demo-icon.svg";
import communicareLogo from "../../assets/img/communicare-logo.svg"
import communicareIcon from "../../assets/img/communicare-icon.svg"
import securLogo from "../../assets/img/secure-logo.svg";
import securIcon from "../../assets/img/secure-icon.svg";
import ultimateLogo from "../../assets/img/ultimate-logo.svg";
import ultimateIcon from "../../assets/img/ultimate-icon.svg"
const LeftBar = () => {
  const navigate = useNavigate();
  const { user, userRole } = useAuth();
  console.log("user role id is===========>",userRole)
  const clientName = process.env.REACT_APP_ClientName;
  const payerFeatures = JSON.parse(
    process.env.REACT_APP_PAYER_FEATURES || "[]"
  );
  const [activeMenu, setActiveMenu] = useState("");
  const menuItems = {
    "Patient Search": { path: "/PatientSearch", icon: "fh_patient_search_2" },
    Application: { path: "/ApplicationList", icon: "fh_dashboard_fill" },
    "User Search": { path: "/UserSearch", icon: "fh_user_search_1" },
  };

  useEffect(() => {
    const firstVisibleFeature = payerFeatures.find(
      (feature) => !(feature === "User Search" && userRole !== 1)
    );
    if (firstVisibleFeature) {
      setActiveMenu(firstVisibleFeature); 
    }
  }, [ userRole]);

  const handleNavigation = (event, to) => {
    event.preventDefault();
    if (!user) {
      navigate("/post-logout");
    } else {
      navigate(to);
    }
  };

  const getLogoSource = () => {
    switch (clientName) {
      case "freedom":
        return freedomLogo;
      case "healthsun":
        return healthSunLogo;
      case "optimum":
        return optimumLogo;
      case "demo":
        return demoLogo;
      case "communicare":
        return communicareLogo
      case "secur":
        return securLogo
      case "ultimate":
        return ultimateLogo
      default:
        return "";
    }
  };

  const getLogoIcon = () => {
    switch (clientName) {
      case "freedom":
        return freedomLogoIcon;
      case "healthsun":
        return healthSunLogoIcon;
      case "optimum":
        return optimumLogoIcon;
      case "demo":
        return demoLogoIcon;
        case "communicare":
        return communicareIcon
      case "secur":
        return securIcon
        case "ultimate":
          return ultimateIcon
      default:
        return "";
    }
  };

  return (
    <nav className="sb-sidenav accordion" id="sidenavAccordion">
      <div className="header-section d-flex justify-content-between align-items-center">
        <div className="client-logo">
          <img
            src={getLogoSource()}
            alt="Client Logo"
            onClick={(e) => {
              const defaultPath =
                payerFeatures.length > 0 && menuItems[payerFeatures[0]]
                  ? menuItems[payerFeatures[0]].path
                  : "/"; // Fallback to "/" if no features are defined
              handleNavigation(e, defaultPath);
            }}
          />
        </div>
        <SidebarToggle />
        <img
          src={getLogoIcon()}
          alt="Client Logo"
          className="client-icon w40"
          onClick={(e) => {
            const defaultPath =
              payerFeatures.length > 0 && menuItems[payerFeatures[0]]
                ? menuItems[payerFeatures[0]].path
                : "/"; // Fallback to "/" if no features are defined
            handleNavigation(e, defaultPath);
          }}
        />
      </div>
      <div className="sb-sidenav-menu">
        <div className="nav">
          {payerFeatures.map((feature,key={feature}) => {
            if (feature === "User Search" && userRole !== 1) {
              return null;
            }

            return (
              menuItems[feature] && (
                <a
                  key={feature}
                  className={
                    "nav-link " + (activeMenu === feature ? "active" : "")
                  }
                  href={menuItems[feature].path}
                  onClick={(e) => {
                    setActiveMenu(feature);
                    handleNavigation(e, menuItems[feature].path);
                  }}
                  data-bs-toggle="tooltip"
                  data-bs-placement="right"
                  title={feature}
                >
                  <div className="sb-nav-link-icon">
                    <i className={menuItems[feature].icon}></i>
                  </div>
                  <span>{feature}</span>
                </a>
              )
            );
          })}
        </div>
      </div>
      <div className="sb-sidenav-footer">
        <div className="footer-info">
          <div className="small-txt">Powered By</div>
          <img
            src={require("../../assets/img/aaneel-poweredby.svg").default}
            alt="mySvgImage"
          />
        </div>
      </div>
    </nav>
  );
};

export default LeftBar;